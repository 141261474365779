/**
 * The Dashboard container
 */
import React from "react";
import PropTypes from "prop-types";
import { Card, CardFooter, Col, Container, Row } from "reactstrap";
import {
  FaCalendar,
  FaBook,
  FaList,
  FaVoteYea,
  FaHandshake,
} from "react-icons/fa";
import { GiCardDraw } from "react-icons/gi";
import { Page } from "@bkry/bowline-components/Page";
import { Link } from "react-router-dom";
import { ScrollToTop as useScrollToTop } from "@bkry/bowline-components/Utils";
import { EditorLoader } from "@bkry/bowline-components/PageBuilder";
import { pageAttributes } from "@bkry/bowline-redux/pages";
import { useSelector } from "react-redux";

/**
 * Dashboard container, the landing page for logged in users
 */
const Dashboard = (props, context) => {
  useScrollToTop();

  const loadedProject = useSelector((state) => state.projects.loadedShow);
  const loadedPage = useSelector((state) => state.pages.loadedPage);
  const showErrorPage = useSelector((state) => state.pages.showError);
  const pageTemp = useSelector(pageAttributes);
  return (
    <Page id="dashboard" title="Dashboard">
      <EditorLoader pageSlug={"home"} />
      {loadedProject &&
        (loadedPage || showErrorPage) &&
        pageTemp?.slug != "home" && (
          <Container>
            <Row>
              <h1 className="mt-5">{context.t("List of enabled Modules")}</h1>
              <Col md={3} className="mb-3">
                <Card className="h-100">
                  <div className="card-img-top text-center py-5">
                    <FaCalendar size="3rem" />
                  </div>
                  <CardFooter className="text-end">
                    <Link
                      to="/events"
                      className="btn btn-outline-primary btn-sm"
                    >
                      {context.t("show")}&nbsp;{context.t("Events")}
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
              <Col md={3} className="mb-3">
                <Card className="h-100">
                  <div className="card-img-top text-center py-5">
                    <FaBook size="3rem" />
                  </div>
                  <CardFooter className="text-end">
                    <Link
                      to="/contents"
                      className="btn btn-outline-primary btn-sm"
                    >
                      {context.t("show")}&nbsp;{context.t("Unlockables")}
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
              <Col md={3} className="mb-3">
                <Card className="h-100">
                  <div className="card-img-top text-center py-5">
                    <FaVoteYea size="3rem" />
                  </div>
                  <CardFooter className="text-end">
                    <Link
                      to="/votings"
                      className="btn btn-outline-primary btn-sm"
                    >
                      {context.t("show")}&nbsp;{context.t("Votings")}
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
              <Col md={3} className="mb-3">
                <Card className="h-100">
                  <div className="card-img-top text-center py-5">
                    <FaList size="3rem" />
                  </div>
                  <CardFooter className="text-end">
                    <Link
                      to="/allowlist"
                      className="btn btn-outline-primary btn-sm"
                    >
                      {context.t("show")}&nbsp;{context.t("Allowlist")}
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
              <Col md={3} className="mb-3">
                <Card className="h-100">
                  <div className="card-img-top text-center py-5">
                    <FaHandshake size="3rem" />
                  </div>
                  <CardFooter className="text-end">
                    <Link
                      to="/deals"
                      className="btn btn-outline-primary btn-sm"
                    >
                      {context.t("show")}&nbsp;{context.t("Partner Deals")}
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
              <Col md={3} className="mb-3">
                <Card className="h-100">
                  <div className="card-img-top text-center py-5">
                    <FaList size="3rem" />
                  </div>
                  <CardFooter className="text-end">
                    <Link
                      to="/tokens"
                      className="btn btn-outline-primary btn-sm"
                    >
                      {context.t("show")}&nbsp;{context.t("Token Search")}
                    </Link>
                  </CardFooter>
                </Card>
              </Col>

              <Col md={3} className="mb-3">
                <Card className="h-100">
                  <div className="card-img-top text-center py-5">
                    <FaList size="3rem" />
                  </div>
                  <CardFooter className="text-end">
                    <Link
                      to="/tokens/my"
                      className="btn btn-outline-primary btn-sm"
                    >
                      {context.t("show")}&nbsp;{context.t("My Tokens")}
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
              <Col md={3} className="mb-3">
                <Card className="h-100">
                  <div className="card-img-top text-center py-5">
                    <GiCardDraw size="3rem" />
                  </div>
                  <CardFooter className="text-end">
                    <Link
                      to="/minting"
                      className="btn btn-outline-primary btn-sm"
                    >
                      {context.t("go to")}&nbsp;{context.t("Minting")}
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
    </Page>
  );
};

/**  define proptype for the 'translation' function  */
Dashboard.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default Dashboard;
